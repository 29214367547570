import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { ChevronRightSmall } from '~components/Svg'
import { noCase } from 'change-case'
import sectionsWithUrls from '~utils/sectionsWithUrls'

const Breadcrumbs = ({ className, color, page }) => {
	return (
		<Wrap className={`${className} cap`} color={color}>
			<Inner>
				<Link to='/'>Home</Link>
				<Divider/>
				{sectionsWithUrls.includes(page?.section) &&
					<>
						<span>{page?.section ? noCase(page?.section) : ''}</span><Divider/>
					</>
				}
				{page?._type === 'article' &&
					<>
						<Link to='/articles'>Articles</Link><Divider/>
					</>
				}
				{page?._type === 'story' &&
					<>
						<Link to='/stories'>Stories</Link><Divider/>
					</>
				}
				{page?._type === 'group' &&
					<>
						<Link to='/panda-learning-hub'>Learning Hub</Link><Divider/>
					</>
				}
				{page?.title}
			</Inner>
		</Wrap>
	)
}

const Wrap = styled.div`
	color: ${props => props.color};
`
const Inner = styled.div`
	
`
const Divider = styled(ChevronRightSmall)`
	height: 10px;
	margin: 0 13px 0 15px;
`

Breadcrumbs.propTypes = {
	className: PropTypes.string,
	color: PropTypes.string,
	page: PropTypes.object,
}

export default Breadcrumbs